div[id^="google_ads_iframe"] {
  margin: 0 auto; }

.show-on-mobile {
  display: none; }

.hide {
  display: none !important; }

.underline {
  text-decoration: underline; }

.rtecenter {
  text-align: center; }

.color.grey {
  color: #404040 !important; }
  .color.grey:hover {
    color: #1861bf !important; }

.color.lightGrey {
  color: #c8c8c8 !important; }
  .color.lightGrey:hover {
    color: #aaa !important; }

.color.green {
  color: #26842c !important; }
  .color.green:hover {
    color: #146b1a !important; }

.color.red {
  color: #db2828 !important; }
  .color.red:hover {
    color: #d01919 !important; }

.dotted {
  text-decoration: none;
  border-bottom: 1px dotted; }

.thin {
  font-weight: 300 !important; }

.smaller {
  font-size: 0.9em !important; }

.smaller {
  font-size: 0.9em;
  font-weight: 400; }

.rainbow-button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer; }

@media screen and (min-width: 1024px) {
  .tablet-down-only {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .tablet-up-only {
    display: none !important; } }

@media screen and (max-width: 1023px) {
  .desktopOnly, .desktop-only {
    display: none !important; } }

@media screen and (max-width: 1200px) {
  .large-desktop-only {
    display: none !important; } }

@media screen and (max-width: 1023px) {
  .ui.menu .item.desktop-only {
    display: none; } }

@media screen and (min-width: 1024px) {
  .ui.menu .item.tablet-down-only {
    display: none; } }

.ui.inverted.menu a.item:hover {
  background: transparent; }

.ui.inverted.menu .dropdown.item:hover {
  background: transparent; }

.ui.inverted.menu .item.hide {
  display: none !important; }

@media screen and (max-width: 1024px) {
  .show-on-tablet-down {
    display: none; } }

@media screen and (min-width: 767px) {
  .show-on-mobile-only {
    display: none !important; } }

.show-on-desktop {
  display: none !important; }
  @media screen and (min-width: 992px) {
    .show-on-desktop {
      display: block !important; } }

#app .ui.inverted.menu .active.item,
#app .ui.inverted.menu .item:hover,
#app .ui.inverted.menu .active.item:hover {
  background: transparent; }
  #app .ui.inverted.menu .active.item.underline::after,
  #app .ui.inverted.menu .item:hover.underline::after,
  #app .ui.inverted.menu .active.item:hover.underline::after {
    content: "";
    width: 100%;
    height: 3px;
    background: white;
    left: 0;
    position: absolute;
    bottom: 5px; }

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word; }

.slash {
  width: calc(100% + 10px);
  height: 1px;
  background-color: #13ab67;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
  position: absolute;
  left: -5px;
  top: 10px; }

.heart.collection {
  cursor: pointer;
  color: #0caa41;
  font-family: outline-icons !important;
  transition: 0.25s; }
  .heart.collection.pending {
    font-family: Icons !important;
    color: #00bd3f80; }
  .heart.collection.hover {
    font-family: Icons !important;
    color: #00bd3f80; }
  .heart.collection.collected {
    color: #0caa41;
    font-family: Icons !important; }

.job-search {
  background: white;
  margin-bottom: 1rem;
  padding-bottom: 14px; }
  .job-search__filter {
    margin-top: 1rem;
    position: relative; }
  .job-search__checkboxes .checkbox {
    margin-right: 20px;
    font-size: 15px; }
  .job-search__dropdown {
    display: inline-block;
    margin-bottom: 10px; }
    @media screen and (min-width: 1024px) {
      .job-search__dropdown {
        position: relative; } }
  .job-search__dropdown-menu {
    z-index: 10;
    background: white;
    max-height: 386px;
    overflow-y: auto;
    display: none;
    position: absolute;
    width: 100%;
    left: 0; }
    @media screen and (min-width: 1024px) {
      .job-search__dropdown-menu {
        width: 360px; } }
    .job-search__dropdown-menu--active {
      display: block; }
  .job-search__dropdown-menu-inner {
    padding: 12px;
    padding-bottom: 8px;
    border: 1px solid #ccc;
    border-top: 0; }
  .job-search__state {
    margin-bottom: 1rem; }
  .job-search__current-search {
    display: inline-block; }
  .job-search__clear-search {
    display: inline-block; }

.swiper-container .swiper-button-next, .swiper-container .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: -12px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #00000033; }

.swiper-container .swiper-button-next:after, .swiper-container .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 10px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1; }

.swiper-container .swiper-pagination-bullet {
  background: darkgrey;
  opacity: 0.5; }
  .swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #ff9600; }

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 2px; }

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%; }

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: ''; }

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.corner-menu {
  position: absolute;
  top: 10px;
  right: 10px; }
  .corner-menu .ui.dropdown .menu > .item {
    font-size: 14px;
    padding: 11px 14px !important; }

.ck-media__wrapper[data-oembed-url*="open.spotify.com"] {
  max-width: 600px !important; }

.ck-media__wrapper[data-oembed-url*="podcasts.apple.com"] {
  text-align: center; }

.field__description {
  color: lightslategrey;
  font-size: 15px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }
  .field__description a {
    color: lightslategrey;
    text-decoration: underline; }
    .field__description a:hover {
      text-decoration: underline; }

.ui.hkese-green.menu .active.item, .ui.menu .hkese-green.active.item {
  border-color: #0caa41 !important;
  color: #0caa41 !important;
  border-bottom-width: 3px;
  font-weight: normal; }

.hkese-green {
  color: #0caa41; }

.ui.dimmer {
  z-index: 2000; }

.ui.large.header {
  font-size: 24px !important; }

.ui.large.header .sub.header {
  font-size: 17px !important; }

.label-tag {
  margin-bottom: 3px !important;
  margin-right: 3px !important;
  text-transform: capitalize !important;
  line-height: 1 !important;
  font-size: 12px !important;
  padding: 6px 10px; }
  .label-tag.basic {
    background: transparent;
    border-color: #d1d6d4;
    font-weight: normal; }
    .label-tag.basic:hover {
      color: inherit;
      border-color: #404040; }
  .label-tag .icon {
    margin-right: 3px; }

.clearfix::after {
  content: "";
  clear: both;
  display: table; }

figure.basic {
  margin: 0;
  margin-bottom: 1em;
  text-align: center; }

figure.image {
  position: relative; }

.zoom-button {
  display: inline-block;
  position: absolute;
  opacity: 0.5;
  bottom: 15px;
  right: 10px;
  z-index: 10; }
  .zoom-button .icon {
    color: #808080; }

.banner {
  position: relative;
  display: inline-block; }
  .banner__label {
    background: white;
    opacity: 0.4;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    font-size: 10px;
    text-transform: uppercase;
    padding: 1px 3px;
    line-height: 1;
    color: slategrey; }
